<template>
    <div @click="stylePortClick" style="background:#fff">
        <div>
           
        </div>
        <Header-top @fabucangwei="fabucangwei" @hangxiangShow="hangxiangShow" ref="headerTop"></Header-top>
      
        
        <div class="bigImage" :class="zIndex ? 'zIndex' : '111'">

            <img src="./banner.png" alt="" srcset="" style="width:100%;height:100%">
             
            <!-- https://yundian2.oss-cn-shanghai.aliyuncs.com/upload/upload/164196947117412.png -->
            <div class="searchBox">
              
                <div class="searchimte">
                    <div class=" kuaisudingcang">
                       <div>
                            运价查询
                       </div>
                        
                    </div>
                 <div class="fabubox">
                        <div class="curor shezhi kuaisudingcang1" @click="fabucangwei" style="margin-left:9px">
                            <i class="iconfont icon-gouwuche" style="color:#fff;margin-right:5px"></i> <span>我要卖舱</span>
                        </div>
                        <div class="curor shezhi kuaisudingcang1 flexcenterSz" @click="hangxiangShow">
                            <div style="background:#fff;height:16px;margin-right:5px;width:16px;border-radius: 3px 3px 3px 3px;" class="flexcenterSz"><img src="./time.png" alt="" srcset="" class="flexcenterSz" style="width:10px;height:10px"> </div>
                            我要寻舱
                        </div>
                 </div>
                    <div class="search">
                        <div class="searchInput">
                            <!-- <el-form  class="demo-form-inline" :inline="true" > -->
                                <!-- <el-form-item label="价格排序"  style="margin-bottom:0"> -->
                                    <div style="width:305px">
                                        <el-select style="width:305px" class="searchSelect" @focus="stylePortClick" v-model="params.startSeaport" filterable clearable :filter-method="getFreightPort" placeholder="深圳">
                                            <el-option
                                            v-for="item in FreightPortList1"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.cname">
                                            <span style="float: left">{{ item.name }}</span>
                                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                                            </el-option>
                                        </el-select>
                                    </div>
                                        <div class="ml-20" style="width:305px">
                                        <el-select class="searchSelect"  @visible-change="visibleChange" @change="blurChange" v-model="params.targetSeaport" filterable clearable :filter-method="getFreightPort1" placeholder="LOS ANGELES,CA" >
                                            
                                                <el-option
                                                v-for="item in FreightPortList3"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.cname">
                                                <span style="float: left">{{ item.name }}</span>
                                                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                                            </el-option>
                                        </el-select>
                                        <div  class="stylePort" :tabindex="1" v-show="stylePort" @blur="stylePortBlur">
                                                <div class="pordtTitle">
                                                    <div v-for="(item,index) in portJson" :key="index" class="jsonTitle  " :class="JsonTitleIndex == index ? 'titleborder1' :''" @click.stop="JsonTitleIndex= index">
                                                        {{item.title}}
                                                    </div>
                                                </div>

                                                <div class="portJsonSmallbox">
                                                    <div  v-for="(prot,index) in portJson[JsonTitleIndex].json" :key="index" >
                                                    
                                                        <div  class="portJsonSmall">
                                                            {{prot.smallTitle}}
                                                        </div>
                                                        <ul class="smallJson clearfix">
                                                            <li v-for="(small,smallindex) in prot.json" :key="smallindex" @click="selectSmall(small.cname)">
                                                                <span href="" :title="small.name + small.cname">
                                                                    <span :class="small.name == params.targetSeaport ? 'selectSmall' :''">
                                                                        {{small.name}}({{small.cname}})
                                                                    </span>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                        </div>
                                        <!-- </van-popup> -->
                                    </div>
                                        <div class="ml-20" style="width:100px;height:48px">
                                    
                                        <el-select v-model="params.shipCompanyName" filterable clearable @focus="stylePortClick" :filter-method="getFreightCompany" placeholder="船司" style="width:100%;height:100%" class="searchSelect1">
                                            <el-option
                                            v-for="item in companyList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.name">
                                            <span style="float: left">{{ item.name }}</span>
                                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="ml-20" style="width:100px">
                                    
                                        <el-select v-model="params.cabinetShape" @focus="stylePortClick" class="searchSelect1" style="width:100%"  clearable placeholder="柜型">
                                            <el-option
                                            v-for="item in getFreightDefineEnumsList.boxModes"
                                            :key="item"
                                            :label="item"
                                            :value="item">
                                            </el-option>
                                        </el-select>
                                    </div>
                                        <div class="ml-20" style="width:160px">
                                        
                                            <el-date-picker
                                            style="width:100%"
                                            class="searchSelect1"
                                            v-model="value1"
                                            type="date"
                                            @focus="stylePortClick"
                                            value-format="yyyy-MM-dd"
                                                :picker-options="pickerOptions"
                                                start-placeholder="发船开始日期"
                                            end-placeholder="发船结束日期"
                                            placeholder="预计开航时间 ">
                                        </el-date-picker>
                                    </div>
                            
                        </div>
                        <div class="searchImage cursor" @click="getData1">
                            
                            <img src="../../assets/images/search.png" alt="" srcset="" >
                        <div>
                            查运价
                        </div>
                        </div>
                    </div>
                    <div class="historyBox">
                        <div style="font-size:18px;line-height: 30px;margin-top:20px">
                            历史搜索
                        </div>
                        <div class="flex flex1" style="width:1000px">
                            <div style="margin-left:15px;margin-top:20px" class="flex flex1" v-for="(item,index) in histroyData" :key="index" >
                                <div class="historyTextBox"  @click="getHistory(item)" v-show="index <5">
                                {{item.startSeaport}} -- {{item.targetSeaport}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
       
        <div class="righttFeid" v-show="rightHide">
            
            <div title="客服" @click="lianxikefu">
                <img src="./kefufiexd.png" alt="" srcset="" class="righticon" style="width:32px;height:26px">
            </div> 
            <div title="消息" @click="xiaoxi">
                <img src="./message.png" alt="" srcset="" class="righticon" style="width:30px;height:26px">
            </div>
             <el-popover
                    placement="left"
                    :width="250"
                    :height="250"
                    trigger="hover"
                >
                <div class="popreptext">
                    若您想要发布舱位信息，可以联系客服了解具体详情~也可点击联系客服
                </div>
                <div class="image" style="margin-top:10px">
                    <img src="./wechart.jpg" alt="" srcset="" style="width:100%">
                
                </div>
                <div class="popreptext">
                    客服微信号:Ryanoz
                </div>
                <div class="popreptext">
                    邮箱:serves@dosbooking.com
                </div>
                <div title="联系我们" slot="reference" style=" width: 44px;
height: 44px;
background: #FFFFFF;
box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
border-radius: 6px;
   display: flex;
   align-items: center;
   justify-content: center;
    margin-top: 12px;
    cursor: pointer;">
                    <img src="./phone.png" alt="" srcset="" class="righticon" style="width:26px;height:26px">
                </div>
            </el-popover>
            
            
            <div title="置顶" @click="tops">
                <img src="./zhiding.png" alt="" srcset="" class="righticon" style="width:24px;height:26px">
            </div>
           
        </div>
        <div class="mainBox" v-loading="loading">
            <div class="mainContent">

               <div class="clearfix">
                   <div class="borderTitle">
                       <div class="mainTitle ">
                                <div class="flex ">
                                    优选航线
                                </div>
                        </div>
                   </div>
                   <div class="gengduo">
                        <div class="tuijian">
                            专属推荐
                        </div>
                        <div class="curor gengduoshangp" @click="getData3">
                             更多商品 <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="tableDataFor clearfix">
                        
                        <div v-for="(item,index) in tableData" :key="item.id" class="clearfix itemBox" @click="yuding(item)" @mouseleave="remarkMouseleave(index)">
                           <div class="box">
                               <div class="boxPort">
                                   <div class="poetText" style="text-align:left">
                                       {{item.startPortName1}}
                                   </div>
                                   <div class="portCenter">
                                       <div  v-if="item.targetPortName == item.transferPortName">
                                            直航
                                        </div>
                                        <div  v-if="item.targetPortName != item.transferPortName">
                                            中转
                                        </div>
                                       <div>
                                           <img src="../../assets/images/jtRight.png" alt="" srcset="" style="width:62px;height:8px">
                                       </div>
                                       <div>
                                           {{item.voyageDay}}天
                                       </div>
                                   </div>
                                   <div class="poetText" style="text-align:right">
                                       {{item.targetPortName}}
                                   </div>
                               </div>
                               <div class="boxInfo">
                                   <div class="boxInfoLeft">
                                       <div>
                                            <el-tooltip placement="bottom">
                                                <div slot="content">截文件时间：{{item.closeFileTime}}<br/>截放行时间：{{item.closePassTime}}<br/>开船时间：{{item.sailTime}}</div>
                                            <div class="">
                                                  截关/开船：{{item.weekcloseFile}}/{{item.weekSail}}
                                            </div>
                                            </el-tooltip>
                                        </div>
                                        <div>
                                            柜型：{{item.cabinetShape}}
                                        </div>
                                        <div>
                                            船司：{{item.shipCompanyName}}
                                        </div>
                                   </div>
                                   <div class="boxInfoRight">
                                       <div class="flex boxInfoRight1">
                                           <div class="remark" style="width:30px" @mouseover="remarkMouseover(index)" v-show="item.remark">备注</div>
                                            <div>
                                            <el-popover placement="bottom" trigger="hover">

                                                <el-table
                                                    :data="item.surchargeAmountDetail"
                                                    style="width: 100%">
                                                    <el-table-column
                                                        prop="feeName"
                                                        label="名称"
                                                        align="center"
                                                        >
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="totalFlag"
                                                        label="单位"
                                                        align="center"
                                                        >
                                                        <template>
                                                            <div v-show="item.totalFlag">
                                                                柜
                                                            </div>
                                                            <div v-show="!item.totalFlag">
                                                                票
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="fee"
                                                        align="center"
                                                        label="单票价格">
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="feeType"
                                                        align="center"
                                                        label="币种">
                                                    </el-table-column>
                                                    </el-table>
                                            <div class="link" slot="reference">
                                                附加费
                                            </div>                                
                                            </el-popover>
                                            </div>
                                       </div>
                                       <div class="money">
                                           ${{item.cabinetAmount}}
                                       </div>
                                   </div>
                               </div>
                           </div>
                           <div class="remarkData" v-show="item.cover">
                               {{item.remark}}
                           </div>
                        </div>
                    </div>
               </div>
            </div>
        </div>
        <!-- table -->
         <div class=""  v-if="tableData.length !=0 ">
            
           <div>
            <transition name="el-zoom-in-center">
                <div  class="tableDataFor clearfix" v-if="act == 1" v-loading="loading"
                        element-loading-spinner="el-icon-loading"
                        header-align="center">
                    <div v-for="item in tableData" :key="item.id" class="clearfix">
                        <div >
                            <div class="tableHeader">
                                <div class="flex leftContent">
                                    <div class="leftContent">
                                        <div class="tableText">
                                                船司名称：
                                        </div>
                                        <div class="tabletextContert">
                                                {{item.shipCompanyName}}
                                        </div>
                                    </div>
                                </div>
                                <div class="flex leftContent">
                                    <div class="flex leftContent">
                                        <div class="tableText">
                                                评分：
                                        </div>
                                        <div class="tabletextContert">
                                            <van-rate :size="14" color="#1677ff" v-model="item.compositeGrade" readonly />
                                                <!-- {{item.shipCompanyName}} -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="teableContent">
                                <div class="tableContentLeft">
                                    <div class="flex" style="margin-top:23px;width: 320px;">
                                        <div class="smllImg">
                                            <img src="./map.png" alt="" srcset="" style="width:100%;height:100%">
                                        </div>
                                            <div class="flex leftContent"  >
                                                <el-tooltip class="item" effect="light" :content="item.startPortName" placement="bottom">
                                                    <div class="port">

                                                    {{item.startPortName1}} 
                                                    </div>
                                                </el-tooltip>
                                                <div class="smallgk">
                                                   <div class="reack">
                                                        <div class="smalltitle1" v-if="item.targetPortName == item.transferPortName">
                                                            直航
                                                        </div>
                                                        <div class="smalltitle1" v-if="item.targetPortName != item.transferPortName">
                                                            中转
                                                        </div>
                                                        <img src="./jiantou.png" alt="" srcset="" style="width:44px;height:44px">
                                                        <div class="smalltitle2"  v-if="item.targetPortName != item.transferPortName">
                                                            {{item.transferPortName}}
                                                        </div>
                                                   </div>
                                                </div>
                                                <el-tooltip class="item" effect="light" :content="item.targetPortName" placement="bottom">
                                                <div class="port" >
                                                    {{item.targetPortName}}
                                                </div>
                                                </el-tooltip>
                                            </div>
                                    </div>
                                    <div style="margin-top:32px" class="flex flexcenter">
                                        <div class="smllImg">
                                            <img src="./riqi.png" alt="" srcset="" style="width:100%;height:100%">
                                        </div>
                                        <div  class="flex leftContent">
                                            <div class="tableText">
                                                截关/开船：
                                            </div>
                                            <el-tooltip placement="bottom">
                                                <div slot="content">截文件时间：{{item.closeFileTime}}<br/>截放行时间：{{item.closePassTime}}<br/>开船时间：{{item.sailTime}}</div>

                                            <div class="time">
                                            <div class="timeBorder">
                                                    {{item.weekcloseFile}}/{{item.weekSail}}
                                            </div>
                                            <div style="margin-left:10px">
                                                {{item.voyageDay}}天
                                            </div>
                                            </div>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <div style="margin-top:16px" class="flex flexcenter">
                                        <div class="smllImg">
                                            <img src="./remark.png" alt="" srcset="" style="width:100%;height:100%">
                                        </div>
                                        <div  class="flex ">
                                            <div>
                                                备注：
                                            </div>
                                            <div class="remark">
                                              <span v-show="item.remark">{{item.remark}}</span><span v-show="!item.remark">--</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-top:16px" class="flex">
                                        <div class="smllImg">
                                            <img src="./gx.png" alt="" srcset="" style="width:100%;height:100%">
                                        </div>
                                        <div  class="flex leftContent">
                                            <div>
                                                柜型:
                                            </div>
                                            <div class="leftContent">
                                               <!-- <span > -->
                                                   <span v-if="!item.freightVoSkuList">{{item.cabinetShape}}</span>
                                               <!-- <span> -->
                                                    <span v-if="item.freightVoSkuList" v-for="list in item.freightVoSkuList" :key="list.id" style="margin-left:7px">
                                                        {{list.cabinetShape}}
                                                    </span>
                                               <!-- </span> -->
                                               <!-- </span> -->
                                               <!-- <span> -->
                                                   <!-- <img src="./xhao.png" alt="" srcset="" style="width:14px;height:14px;margin-left:7px;margin-right:7px"> -->
                                               <!-- </span> -->
                                                <!-- <span>
                                                    {{item.stockNum}}个
                                                </span> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tableRight">
                                    <div class="jiage">
                                        ${{token == '' ? item.cabinetAmount : item.cabinetAmount}}
                                    </div>
                                    <div class="fujiafei">
                                        <el-popover placement="bottom" trigger="hover">

                                                <el-table
                                                    :data="item.surchargeAmountDetail"
                                                    style="width: 100%">
                                                    <el-table-column
                                                        prop="feeName"
                                                        label="名称"
                                                        align="center"
                                                        >
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="totalFlag"
                                                        label="单位"
                                                        align="center"
                                                        >
                                                        <template>
                                                            <div v-show="item.totalFlag">
                                                                柜
                                                            </div>
                                                            <div v-show="!item.totalFlag">
                                                                票
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="fee"
                                                        align="center"
                                                        label="单票价格">
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="feeType"
                                                        align="center"
                                                        label="币种">
                                                    </el-table-column>
                                                    </el-table>
                                            <div class="link" slot="reference">
                                                附加费
                                            </div>                                
                                        </el-popover>

                                        
                                    </div>
                                    <div class="kuicangfei leftContent">
                                        <div class="leftContent">
                                             履约保证金(USD)：<span>{{item.depositAmount}}</span>
                                        </div>
                                        <div class="leftContent">
                                            <el-tooltip class="item" effect="dark" content="订单结束后返还" placement="top-start">
                                             <img style="width:14px;height:14px;margin-left:4px; cursor: pointer" src="./tixing.png" alt="" srcset="">
                                            </el-tooltip>
                                            <!-- <img style="width:14px;height:14px" src="./tixing.png" alt="" srcset=""> -->
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <div @click="yuding(item)" class="ydcg">
                                            预定舱位
                                        </div>
                                        <div class="kefu1" style="margin-top:13px" @click="xunwen(item)">
                                            <img src="./kefu.png" alt="" srcset="" style="width:22px;height:22px">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </transition>

           </div>
        </div>
        <div class="nodatabox" v-show="tableData.length ==0 && seachInount == 1">
            <div class="dis">
                    <img src="./nodata.png" alt="" srcset="">
                    <div style="margin-top:24px">
                        暂无数据
                    </div>
                    <div style="margin-top:12px;color:rgba(102, 102, 102, 1)">
                        试试更换筛选条件
                    </div>
            </div>
        </div>
    
     <div v-if="swiperList.length != 0" style="padding-bottom:30px">
           <swiper ref="mySwiper" :options="swiperOptions">
            <!-- <div class="swiper-wrapper"> -->
            <swiper-slide class="swiper-slide" v-for="tabl in swiperList" :key="tabl.id" >
                     <div class="mainBox" >
                         <!-- {{tabl.list ? tabl.list.length= 0 : true}} {{tabl.list}} -->
                <div class="mainContent">

                <div class="clearfix">
                 
                    <div class="gengduo">
                            <div class="tuijian1">
                               {{tabl.preName}}
                            </div>
                            <div class="curor gengduoshangp" @click="getData3">
                                更多商品 <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                        <div class="tableDataFor clearfix">
                            
                            <div v-for="(item,index) in tabl.list" :key="item.id" class="clearfix itemBox" @click="yuding(item)" >
                            <div class="box">
                                <div class="boxPort">
                                    <div class="poetText" style="text-align:left">
                                        {{item.startPortName1}}
                                    </div>
                                    <div class="portCenter">
                                        <div  v-if="item.targetSeaport == item.changeSeaport">
                                                直航
                                            </div>
                                            <div  v-if="item.targetSeaport != item.changeSeaport">
                                                中转
                                            </div>
                                        <div>
                                            <img src="../../assets/images/jtRight.png" alt="" srcset="" style="width:62px;height:8px">
                                        </div>
                                        <div>
                                            {{item.voyageDay}}天
                                        </div>
                                    </div>
                                    <div class="poetText" style="text-align:right">
                                        {{item.targetSeaport}}
                                    </div>
                                </div>
                                <div class="boxInfo">
                                    <div class="boxInfoLeft">
                                        <div>
                                                <el-tooltip placement="bottom">
                                                    <div slot="content">截文件时间：{{item.closeFileTime}}<br/>截放行时间：{{item.closePassTime}}<br/>开船时间：{{item.sailTime}}</div>

                                                <div class="">
                                                    截关/开船：{{item.weekcloseFile}}/{{item.weekSail}}
                                                </div>
                                                </el-tooltip>
                                            </div>
                                            <div>
                                                柜型：{{item.cabinetShape}}
                                            </div>
                                            <div>
                                                船司：{{item.shipCompanyName}}
                                            </div>
                                    </div>
                                    <div class="boxInfoRight">
                                        <div class="flex boxInfoRight1">
                                            <div class="remark" style="width:30px" @mouseover="remarkMouseover(index)" v-show="item.remark">备注</div>
                                                <div>
                                                <el-popover placement="bottom" trigger="hover">

                                                    <el-table
                                                        :data="item.surchargeAmountDetail"
                                                        style="width: 100%">
                                                        <el-table-column
                                                            prop="feeName"
                                                            label="名称"
                                                            align="center"
                                                            >
                                                        </el-table-column>
                                                        <el-table-column
                                                            prop="totalFlag"
                                                            label="单位"
                                                            align="center"
                                                            >
                                                            <template>
                                                                <div v-show="item.totalFlag">
                                                                    柜
                                                                </div>
                                                                <div v-show="!item.totalFlag">
                                                                    票
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column
                                                            prop="fee"
                                                            align="center"
                                                            label="单票价格">
                                                        </el-table-column>
                                                        <el-table-column
                                                            prop="feeType"
                                                            align="center"
                                                            label="币种">
                                                        </el-table-column>
                                                        </el-table>
                                                <div class="link" slot="reference">
                                                    附加费
                                                </div>                                
                                                </el-popover>
                                                </div>
                                        </div>
                                        <div class="money">
                                            ${{item.cabinetAmount}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="remarkData" v-show="item.cover">
                                {{item.remark}}
                            </div>
                            </div>
                        </div>
                </div>
                </div>
                </div>
                  
                </swiper-slide>
           
                <!-- <div class="swiper-slide">Slide 2</div>
                <div class="swiper-slide">Slide 3</div> -->
            <!-- </div> -->
            <!-- 如果需要分页器 -->
         
            <!-- 如果需要滚动条 -->
            
          </swiper>
              <div class="swiper-pagination"></div>
     </div>
     <div class="imageBox">
        <div style="margin:0 auto" class="imgBox">
             <img src="./1.png" alt="" srcset="" style="width:100%;">
             <div class="tiyanbutton" @click="getData3">
                 立即体验
             </div>
        </div>
     </div>
     <div class="imageBox">
         <div class="box2">
           <div class="jianjie">
                <div class="jianjiecenter">
                    <div>
                        <img src="./image/xj.png" alt="" srcset="" style="width:186px;height:168px">
                    </div>
                    <div class="jianjietitle jianjiecenter">
                        寻价查价
                    </div>
                    <div  class="jianjietitle1 jianjiecenter">
                        选择需要的港口信息，立即查询当日价格
                    </div>
                </div>
                <div class="jianjiecenter" style="margin-left:285px">
                    <div>
                        <img src="./image/yx.png" alt="" srcset="" style="width:186px;height:168px">
                    </div>
                    <div class="jianjietitle jianjiecenter">
                        海豚优选
                    </div>
                    <div  class="jianjietitle1 jianjiecenter" style="width:197px">
                       海豚寻舱团队为客户每周更新经过多方面对比，层层筛选出最优质的线路
                    </div>
                </div>
                <div class="jianjiecenter" style="margin-left:271px">
                    <div>
                        <img src="./image/xl.png" alt="" srcset="" style="width:186px;height:168px">
                    </div>
                    <div class="jianjietitle jianjiecenter">
                        线路专区 
                    </div>
                    <div  class="jianjietitle1 jianjiecenter" style="width: 178px;">
                        线路分类更加清晰、简洁
如：美加线、欧洲、拉丁美洲等
                    </div>
                </div>
           </div>
            <div class="jianjie">
                <div class="jianjiecenter">
                    <div>
                        <img src="./image/kf.png" alt="" srcset="" style="width:198px;height:181px">
                    </div>
                    <div class="jianjietitle jianjiecenter">
                        在线客服
                    </div>
                    <div  class="jianjietitle1 jianjiecenter" style="width:224px">
                        24小时客服为您提供解答服务，人工客服在线北京时间：周一至周五，9:00-18:00
                    </div>
                </div>
                <div class="jianjiecenter" style="margin-left:285px">
                    <div>
                        <img src="./image/zh.png" alt="" srcset="" style="width:186px;height:168px">
                    </div>
                    <div class="jianjietitle jianjiecenter">
                        专属售前
                    </div>
                    <div  class="jianjietitle1 jianjiecenter" style="width:199px">
                      预定舱位后，会由专属的售前人员全程跟踪服务，解决在订舱后可能会出现的一些问题，帮助您顺利拿下SO单
                    </div>
                </div>
                <div class="jianjiecenter" style="margin-left:271px">
                    <div>
                        <img src="./image/dw.png" alt="" srcset="" style="width:135px;height:170px">
                    </div>
                    <div class="jianjietitle jianjiecenter">
                        订单跟踪 
                    </div>
                    <div  class="jianjietitle1 jianjiecenter" style="width: 152px;">
                     预定仓位后根据订单编号，实时查询订舱状态
                    </div>
                </div>
           </div>
         </div>
     </div>
       <div class="imageBox imageBoxBack">
            <div class="box2 box1">
                <div class="boxLeft">
                    <!-- <div> -->
                         <div class="zhuanshu">
                              海豚寻舱寻舱卖舱托管服务
                         </div>
                         <div class="boxLeftText" style="margin-top:50px">
                             如需定制寻舱卖舱服务，请联系我们咨询详情
                         </div>
                         <div class="boxLeftText">
                             我们的联系方式：0755-25100671
                         </div>
                         <div class="boxLeftText">
                             24小时竭诚为您服务
                         </div>
                    <!-- </div> -->
                   
                </div>
                <div class="boxRight">
                     <div style="margin-bottom:20px">
                          海豚寻舱托管客服微信：
                    </div>
                    <div>
                        <img src="./../common/wechart.jpg" alt="" srcset="" style="width: 182px;height: 185px;margin-left:12px">
                    </div>
                </div>
            </div>
       </div>
      <!-- <div class="imageBox">
        <div style="margin:0 auto">
             <img src="./2.png" alt="" srcset="" style="width:100%;">
        </div>
     </div> -->
     <!-- <div> -->
          <div class="imageBox">
            <div class="box2 box1" style="margin-bottom:50px;margin-top:50px">
               
               <div>
                    <div class="chuansititle">
                        我们已服务4000+客户
                    </div>
                   <div class="flex" style="margin-top:20px">
                   <div class="chuansiBox">
                    <img src="./image/1.jpg" alt="" srcset=""  style="width:200px;height:200px">
                    </div>
                    <div class="chuansiBox" style="margin-left:20px">
                            <img src="./image/2.png" alt="" srcset=""  style="width:200px;height:200px">
                    </div>
                    <div class="chuansiBox" style="margin-left:20px">
                            <img src="./image/3.png" alt="" srcset=""  style="width:200px;height:200px">
                    </div>
                    <div class="chuansiBox" style="margin-left:20px">
                            <img src="./image/4.png" alt="" srcset="" style="width:200px;">
                    </div>
               </div>
               <div class="flex" style="margin-top:20px">
                   <div class="chuansiBox">
                    <img src="./image/5.png" alt="" srcset="" style="width:200px;">
                    </div>
                    <div class="chuansiBox" style="margin-left:20px">
                            <img src="./image/6.png" alt="" srcset="" style="width:200px">
                    </div>
                    <div class="chuansiBox" style="margin-left:20px">
                            <img src="./image/7.png" alt="" srcset="" style="width:200px">
                    </div>
                    <div class="chuansiBox" style="margin-left:20px">
                            <img src="./image/8.png" alt="" srcset="" style="width:200px">
                    </div>
               </div>
               <div class="flex" style="margin-top:20px">
                   <div class="chuansiBox">
                    <img src="./image/9.png" alt="" srcset="" style="">
                    </div>
                    <div class="chuansiBox" style="margin-left:20px">
                            <img src="./image/10.png" alt="" srcset="" style="width:200px">
                    </div>
                    <div class="chuansiBox" style="margin-left:20px">
                            <img src="./image/11.png" alt="" srcset="" style="width:200px">
                    </div>
                    <div class="chuansiBox" style="margin-left:20px">
                            <img src="./image/12.png" alt="" srcset="" style="width:200px">
                    </div>
               </div>
               </div>
            </div>
        </div>
     <!-- </div> -->
        <!-- <div class="mainBox">
            <div class="mainContent">

               <div class="clearfix">
                   <div class="borderTitle">
                       <div class="mainTitle ">
                                <div class="flex ">
                                    优选航线
                                </div>
                        </div>
                   </div>
                   <div class="gengduo">
                        <div class="tuijian">
                            专属推荐
                        </div>
                        <div class="curor gengduoshangp" @click="getData1">
                             更多商品 <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="tableDataFor clearfix">
                        
                        <div v-for="(item,index) in tableData" :key="item.id" class="clearfix itemBox" @click="yuding(item)" @mouseleave="remarkMouseleave(index)">
                           <div class="box">
                               <div class="boxPort">
                                   <div class="poetText" style="text-align:left">
                                       {{item.startPortName1}}
                                   </div>
                                   <div class="portCenter">
                                       <div  v-if="item.targetPortName == item.transferPortName">
                                            直航
                                        </div>
                                        <div  v-if="item.targetPortName != item.transferPortName">
                                            中转
                                        </div>
                                       <div>
                                           <img src="../../assets/images/jtRight.png" alt="" srcset="" style="width:62px;height:8px">
                                       </div>
                                       <div>
                                           {{item.voyageDay}}天
                                       </div>
                                   </div>
                                   <div class="poetText" style="text-align:right">
                                       {{item.targetPortName}}
                                   </div>
                               </div>
                               <div class="boxInfo">
                                   <div class="boxInfoLeft">
                                       <div>
                                            <el-tooltip placement="bottom">
                                                <div slot="content">截文件时间：{{item.closeFileTime}}<br/>截放行时间：{{item.closePassTime}}<br/>开船时间：{{item.sailTime}}</div>

                                            <div class="">
                                                  截关/开船：{{item.weekcloseFile}}/{{item.weekSail}}
                                            </div>
                                            </el-tooltip>
                                        </div>
                                        <div>
                                            柜型：{{item.cabinetShape}}
                                        </div>
                                        <div>
                                            船司：{{item.shipCompanyName}}
                                        </div>
                                   </div>
                                   <div class="boxInfoRight">
                                       <div class="flex boxInfoRight1">
                                           <div class="remark" style="width:30px" @mouseover="remarkMouseover(index)" v-show="item.remark">备注</div>
                                            <div>
                                            <el-popover placement="bottom" trigger="hover">

                                                <el-table
                                                    :data="item.surchargeAmountDetail"
                                                    style="width: 100%">
                                                    <el-table-column
                                                        prop="feeName"
                                                        label="名称"
                                                        align="center"
                                                        >
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="totalFlag"
                                                        label="单位"
                                                        align="center"
                                                        >
                                                        <template slot-scope="scope">
                                                            <div v-show="item.totalFlag">
                                                                柜
                                                            </div>
                                                            <div v-show="!item.totalFlag">
                                                                票
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="fee"
                                                        align="center"
                                                        label="单票价格">
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="feeType"
                                                        align="center"
                                                        label="币种">
                                                    </el-table-column>
                                                    </el-table>
                                            <div class="link" slot="reference">
                                                附加费
                                            </div>                                
                                            </el-popover>
                                            </div>
                                       </div>
                                       <div class="money">
                                           ${{item.cabinetAmount}}
                                       </div>
                                   </div>
                               </div>
                           </div>
                           <div class="remarkData" v-show="item.cover">
                               {{item.remark}}
                           </div>
                        </div>
                    </div>
               </div>
            </div>
        </div> -->
        <el-dialog
        title="航线提醒："
        :visible.sync="dialogVisible"
        width="600px"
        :before-close="handleClose">
        <el-form label-width="110px">
             <el-form-item label="起始港：">
                 <el-select v-model="jiangjia.startSeaport" filterable clearable :filter-method="getFreightPort3" placeholder="起运港">
                        <el-option
                        v-for="item in FreightPortList4"
                        :key="item.id"
                        :label="item.name"
                        :value="item.cname">
                        <span style="float: left">{{ item.name }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                        </el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label="中转港：">
                <el-select v-model="jiangjia.changeSeaport" filterable clearable :filter-method="getFreightPort4" placeholder="起运港">
                        <el-option
                        v-for="item in FreightPortList5"
                        :key="item.id"
                        :label="item.name"
                        :value="item.cname">
                        <span style="float: left">{{ item.name }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                        </el-option>
                    </el-select>
            </el-form-item>
             <el-form-item label="目的港：">
                  <el-select v-model="jiangjia.targetSeaport" filterable clearable :filter-method="getFreightPort5" placeholder="起运港">
                        <el-option
                        v-for="item in FreightPortList6"
                        :key="item.id"
                        :label="item.name"
                        :value="item.cname">
                        <span style="float: left">{{ item.name }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                        </el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label="船司：">
                 <el-select v-model="jiangjia.shipCompanyName" filterable clearable :filter-method="getFreightCompany1" placeholder="船司" >
                    <el-option
                    v-for="item in companyList1"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="预算单柜价格：">
                <el-input v-model="jiangjia.cabinetAmountStart"></el-input> ~ <el-input v-model="jiangjia.cabinetAmountEnd"></el-input>
            </el-form-item>
             <el-form-item label="柜型：">
                <el-select v-model="jiangjia.cabinetShape" clearable placeholder="柜型">
                    <el-option
                    v-for="item in getFreightDefineEnumsList.boxModes"
                    :key="item"
                    :label="item"
                    :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            
            <!-- <el-row>
                <el-col :span="10">
                    <el-form-item label="预算单柜价格：">
                        <el-input></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                   
                </el-col>
            </el-row> -->
            <el-form-item label="发船日期：">
                <el-date-picker
                    v-model="value2"
                    type="datetimerange"
                    align="right"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                    </el-date-picker>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            
            <el-button type="primary" @click="addSearchFreightNotify">确认提醒</el-button>
            <el-button @click="handleClose">取 消</el-button>
        </span>
        </el-dialog>
            <sellingPort ref="sellingPort" @zindehide="zindehide"></sellingPort>  
            <seekPort ref="seekPort" @zindex="zindehide" @zindehide="zindehide"></seekPort> 
            <notify ref="notify"></notify>
        <footer-bottom></footer-bottom>
        <!--  -->
    </div>
    
</template>
<script>
import HeaderTop from '../common/header.vue'
import footerBottom from '../common/footer.vue'
import sellingPort from '../common/sellingPort.vue'
import seekPort from '../common/seekPort.vue'
import notify from '../common/notify.vue'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
let _ = require('lodash')
export default {
   components:{HeaderTop,footerBottom,sellingPort,seekPort,swiper,swiperSlide,notify},
   data () {
        return{
            zIndex:false,
            JsonTitleIndex:0,
            act:'2',
            // value:5,
            value1:'',
          FreightPortList1:[],
          FreightPortList2:[],
          FreightPortList3:[],
          FreightPortList4:[],
          FreightPortList5:[],
          FreightPortList6:[],
          companyList1:[],
          companyList:[],
          getFreightDefineEnumsList:[],
          dialogVisible:false,
          value2:[],
        //   value1:[],
          portJson:[
              {
                   json:[]
              }
             
          ],
          tableData: [
            ],
            params:{
                startSeaport:'',
                changeSeaport:'',
                
                targetSeaport:'',
                shipCompanyName:'',
                cabinetShape:'',
                pageSize:20,
                pageIndex:1,
                status:1,
                order:'',
                sailTime:''
            },
            // cabinet_num desc 柜量降序 surcharge_amount asc 附加费升序 cabinet_amount asc 价格升序
            jiangjia:{
                 startSeaport:'',
                changeSeaport:'',
                targetSeaport:'',
                shipCompanyName:'',
                cabinetShape:'',
                cabinetAmountStart:'',
                cabinetAmountEnd:''
            },
            descList:[
                {text:'默认排序'}
            ],
            seachInount:0,
           compositeGrade:5,
            total:0,
            loading:false,
            token:'',
            JsonTitle:'',
            JsonTitleIndex:0,
            stylePort:false,
            rightHide:false,
            pickerOptions:{
                    disabledDate(time) {
                       
                        return time.getTime() < Date.now() - 8.64e7//禁止选择今天以前的时间
                    },
                },
            swiperList:[
              
            ],
            swiperOptions:{
            initialSlide:0,//设定初始化时slide的索引

               slidesPerView:1,

                notNextTick: true,

                loop : false,

                 autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: true,
                },
                
               pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    clickableClass : 'my-pagination-clickable',
                    bulletActiveClass: 'bulletActiveClass'
                },

                paginationClickable: true,

                centeredSlides: true,

                autoplayDisableOnInteraction: false,
            },
            histroyData:[]
        }
    },
    created(){
        this.getFreightPortJson()
        this.getClist()
        this.getData2()
          
            // let userInfo = JSON.parse('userInfo')
            // userInfo.bizStatus == 'checked'
        // debugger
    },
    mounted(){
       
        (function(a, b, c, d, e, j, s) {
            a[d] = a[d] || function() {
                (a[d].a = a[d].a || []).push(arguments)
            };
            j = b.createElement(c),
                s = b.getElementsByTagName(c)[0];
            j.async = true;
            j.charset = 'UTF-8';
            j.src = 'https://static.meiqia.com/widget/loader.js';
            s.parentNode.insertBefore(j, s);
        })(window, document, 'script', '_MEIQIA');
        _MEIQIA('entId', '3b5f06f295f4877f71e2e3b515160807');
        _MEIQIA('withoutBtn');
         _MEIQIA('hidePanel');
       
        
        this.getFreightCompany()
        this.getFreightCompany1()
        this.getFreightDefineEnums()
        this.getFreightPort()
        this.getFreightPort1()
        this.getFreightPort2()
        this.getFreightPort3()
        this.getFreightPort4()
        this.getFreightPort5()
          var _this = this;
         window.addEventListener('scroll',function(){
             
         })
        window.addEventListener('scroll', function() {//监听滚动
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;//为了兼容
            if (scrollTop >= 108) {
            //如果当前滚动位置大于您的所需要达到的高度则显示置顶元素，反之不显示
                _this.rightHide = true;
                
            } else {
                _this.rightHide = false;
            }
        })
        
       
       
        if( localStorage.getItem('histroyData')){

             this.histroyData = this.unique(JSON.parse(localStorage.getItem('histroyData')))
        }
         let token = localStorage.getItem('token')
             if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                token = ''
                // this.$router.push('/login')
            } else {
               token = token
                let userInfo = JSON.parse(localStorage.getItem('userInfo')).user;
                // item.user.bizStatus || item.user.bizStatus == 'waitCheck'
                // !item.user.bizStatus || item.user.bizStatus == 'waitCheck'
                if( userInfo.bizStatus =='waitCheck'){
                    console.log(userInfo)
                //    this.$refs.notify.showNotify()
                }
            }
            this.token = token
       
    },
    methods: {
        unique(arr) {
           
             for (var i = 0; i < arr.length - 1; i++) {
                for (var j = i + 1; j < arr.length; j++) {
                    if (arr[i].startSeaport == arr[j].startSeaport && arr[i].targetSeaport == arr[j].targetSeaport) {
                        arr.splice(j, 1);
                        //因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
                        j--;
                    }
                }
            }
            return arr  
             console.log(arr,'  console.log(arr) ')
        } ,
        getClist(){
            this.$post('/hotZone/clist').then(res =>{
                let data = res.data.list
                data.forEach(ele =>{
                      ele.list = []
                   this.getfreightHotZones(ele).then(res =>{
                     
                        res.forEach(ele => {
                            
                            let data1 = new Date(ele.sailTime).getDay()
                            let data2 = new Date(ele.closePassTime).getDay()
                            if(data1 == 0){
                                ele.weekSail = 7
                            } else {
                                ele.weekSail = data1
                            }
                            if(data2 == 0){
                                ele.weekcloseFile = 7
                            } else {
                                ele.weekcloseFile = data2
                            }
                            // console.log(ele.surchargeAmountDetail != "[]",typeof ele.surchargeAmountDetail)
                            if(ele.surchargeAmountDetail){
                            
                            if(ele.surchargeAmountDetail != "[]" && ele.surchargeAmountDetail != "[\u0000]"){
                                    ele.surchargeAmountDetail = JSON.parse(ele.surchargeAmountDetail)
                                let usdTotal = 0
                                let cnyTotal = 0
                                let cnyList = []
                                let usdList = []
                                ele.surchargeAmountDetail.forEach(ele1=>{
                                    // debugger
                                    if(ele1.feeType == 'CNY'){
                                        cnyTotal += ele1.fee
                                        
                                    }
                                    if(ele1.feeType == 'USD'){
                                    usdTotal += ele1.fee
                                    
                                    }
                                    ele.usdTotal = usdTotal
                                    ele.cnyTotal = cnyTotal
                                })
                            } else {
                                ele.surchargeAmount = 0
                                delete ele.surchargeAmountDetail
                            }
                            }
                            if(ele.supplyBizUserName) {
                                ele.mingzi =  ele.supplyBizUserName.substr(0,1) + "*"
                            } else {
                                // ele.mingzi = this.randomName()
                            }
                            if(ele.supplyBizUserPhone){
                                ele.tuomin = this.randomPhoneNumber()
                                //  ele.tuomin = ele.supplyBizUserPhone.substr(0, 3) + "****" + ele.supplyBizUserPhone.substr(7)
                            } else {
                                ele.tuomin = this.randomPhoneNumber()
                            }
                            if(ele.remark == '备注:'){
                                delete ele.remark
                            }
                            // <span>{{item.depositAmount}}</span>
                            if(ele.depositAmount == 0){
                                ele.depositAmount = '--'
                            } else if(!ele.depositAmount){
                                ele.depositAmount = '--'
                            }
                            // var reg = /[\u4e00-\u9fa5]/g;
                            // var names = ele.startPortName.match(reg);
                            // console.log(this.getParenthesesStr(ele.startPortName))
                            ele.startPortName1=  ele.startSeaport
                            ele.compositeGrade = 5
                            ele.closeFileTime = ele.closeFileTime.substr(0, 11)
                            // debugger
                            ele.closePassTime = ele.closePassTime.substr(0, 11)
                            ele.sailTime = ele.sailTime.substr(0, 11)
                            // ele.
                            // val.substr(0, 3) + "****" + val.substr(7)
                            
                            //  var dateArray = element.sailTime.split("-");
                            // date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
                        });
                        ele.list = res
                    })
                   
                })
                // var arr2=data.filter(function (item) {
                //         return item.list.length == 0 || item.list;
                // })
                // this.swiperList = data
                // data.forEach((ele) =>{
                
                // })
               this.swiperList = data
                //  let newArr = data.filter(item => {
                //      console.log(item.list,'item')
                //     return item
                // })
                console.log(this.swiperList,'this.swiperList')
            })
        },
         filterList(data) {
            let itemValue = data.filter(item =>{
                console.log('data', item.list ? item.list : [])
                 return item.list.length != 0
            })
           
                return itemValue
        },
       async getfreightHotZones(val){
            let params = {
                idList:val.freightIds,
                sortRule:val.sortRule
            }
           let list= []
           let _this = this
            await this.$post('/freight/freightHotZones?idList=' + val.freightIds + '&sortRule=' + val.sortRule,params).then(res =>{
                
                list = res.data.list
                //  return list
            })
            return list
        },
        // /freight/freightHotZones?idList=164782619776006,164782619833784&sortRule=2
        remarkMouseover(index){
            let tableData = JSON.parse(JSON.stringify(this.tableData))
            tableData[index].cover = true
            this.tableData = tableData
            console.log(1111,tableData,index)
        },
        remarkMouseleave(index){
            let tableData = this.tableData
            tableData[index].cover = false
            this.tableData = tableData
            console.log(222)
            // debugger
        },
        zindehide(){
            this.zIndex = false
        },
        zindeshow(){
            // debugger
            this.zIndex = true
        },
        load(){
           this.params.pageIndex = this.params.pageIndex + 1
            this.getData2() 
        },
        tops(){
        //使得返回顶部过渡置增加交互效果
        var timer = setInterval(() => document.documentElement.scrollTop <= 0 ? clearInterval(timer) : window.scrollTo(0, document.documentElement.scrollTop - 10), 1);
        },
        stylePortClick(){
            // alert('11')
            this.stylePort = false
        },
        stylePortBlur(){
            this.stylePort = false
        },
        getHistory: _.throttle(function (data) {

                 this.params.startSeaport = data.startSeaport

                this.params.targetSeaport =  data.targetSeaport
               
             console.log(this.params,'paramsIndex')
            // debugger
            // return false
            this.seachInount = 1
            this.params.pageIndex = 1
            //  this.getData()
              if(this.value1 != null && this.value1.length != 0){
                // this.params.startDate = this.value1 + ' 00:00:00'
                // this.params.eneDate = this.value1 + ' 23:59:59'
                this.params.sailTime = this.value1 + ' 00:00:00'
            } else {
                this.params.startDate = ''
                this.params.eneDate = ''
            }
             if(this.value1 != null && this.value1.length != 0){
                // this.params.startDate = this.value1 + ' 00:00:00'
                // this.params.eneDate = this.value1 + ' 23:59:59'
                this.params.sailTime = this.value1 + ' 00:00:00'
            }
            //  return false
             this.$router.push('/search?value=' + JSON.stringify(this.params) )
            let params = this.params
            params.userId =  localStorage.getItem("empId")
            let userInfo = localStorage.getItem('userInfo')
            if(userInfo !=null){
                 params.mobileNum  = JSON.parse(userInfo).user.phone
            }
            if(this.params.startSeaport == undefined){
                this.params.startSeaport = ''
                // delete params.startSeaport
            }
             if(this.params.targetSeaport == undefined){
                this.params.targetSeaport = ''
                // delete params.targetSeaport
            }
            //  startSeaport:this.params.startSeaport,
            //   targetSeaport:this.params.targetSeaport,
            //   companyName:this.params.shipCompanyName,
            //   cabinetShape:this.params.cabinetShape,
            //   startDate:this.params.sailTimeStart,
            //   endDate:this.params.sailTimeEnd
           
           
            this.$post('/index/click?sailTime=' + this.params.sailTime + '&=' + '&startSeaport=' + this.params.startSeaport + '&targetSeaport=' + this.params.targetSeaport + '&companyName='+ this.params.shipCompanyName + '&cabinetShape=' + this.params.cabinetShape + '&mobileNum=' + params.mobileNum + '&userId=' + this.params.userId,this.params).then(res =>{
                
            })
            // return false
         }, 3000),
         getData3(){
           this.$router.push('/search?value=')
         },
        getData1: _.throttle(function () {
            let histroyData = localStorage.getItem('histroyData')
           
            if( this.params.startSeaport == '' || this.params.startSeaport == undefined){
                 this.params.startSeaport = '深圳'
                this.$set(this.params,'startSeaport','深圳')
            }
            if( this.params.targetSeaport == '' || this.params.targetSeaport == undefined){
              
                this.params.targetSeaport = 'LOS ANGELES,CA'
                  this.$set(this.params,'targetSeaport','LOS ANGELES,CA')
            }
             if(this.params.startSeaport && this.params.targetSeaport){
                if (!histroyData){
                    histroyData = []
                } else {
                    histroyData = JSON.parse(histroyData)
                }
                histroyData.unshift({
                    startSeaport:this.params.startSeaport,
                    targetSeaport:this.params.targetSeaport
                })
                localStorage.setItem('histroyData',JSON.stringify(histroyData))
            }
             console.log(this.params,'paramsIndex')
            // debugger
            // return false
            this.seachInount = 1
            this.params.pageIndex = 1
            //  this.getData()
              if(this.value1 != null && this.value1.length != 0){
                // this.params.startDate = this.value1 + ' 00:00:00'
                // this.params.eneDate = this.value1 + ' 23:59:59'
                this.params.sailTime = this.value1 + ' 00:00:00'
            } else {
                this.params.startDate = ''
                this.params.eneDate = ''
            }
             if(this.value1 != null && this.value1.length != 0){
                // this.params.startDate = this.value1 + ' 00:00:00'
                // this.params.eneDate = this.value1 + ' 23:59:59'
                this.params.sailTime = this.value1 + ' 00:00:00'
            }
            //  return false
             this.$router.push('/search?value=' + JSON.stringify(this.params) )
            let params = this.params
            params.userId =  localStorage.getItem("empId")
            let userInfo = localStorage.getItem('userInfo')
            if(userInfo !=null){
                 params.mobileNum  = JSON.parse(userInfo).user.phone
            }
            if(this.params.startSeaport == undefined){
                this.params.startSeaport = ''
                // delete params.startSeaport
            }
             if(this.params.targetSeaport == undefined){
                this.params.targetSeaport = ''
                // delete params.targetSeaport
            }
            //  startSeaport:this.params.startSeaport,
            //   targetSeaport:this.params.targetSeaport,
            //   companyName:this.params.shipCompanyName,
            //   cabinetShape:this.params.cabinetShape,
            //   startDate:this.params.sailTimeStart,
            //   endDate:this.params.sailTimeEnd
           
           
            this.$post('/index/click?sailTime=' + this.params.sailTime + '&=' + '&startSeaport=' + this.params.startSeaport + '&targetSeaport=' + this.params.targetSeaport + '&companyName='+ this.params.shipCompanyName + '&cabinetShape=' + this.params.cabinetShape + '&mobileNum=' + params.mobileNum + '&userId=' + this.params.userId,this.params).then(res =>{
                
            })
            // return false
           
        }, 3000),

        // getData1(){
        //     this.seachInount = 1
        //     // debugger
        //     console.log(_)
        //     _.throttle(function(){
        //         debugger
        //         this.getData()
        //     },3000)
        //     // _.throttle( ,3000)
           
        // },
        getFreightPortJson(){
            // axios.post('https://jilingniu.com:8700/system/getFreightPortJson1').then(res =>{
            //     console.log(11111,res.data.data)
            //         this.portJson = res.data.data.list
            //         this.JsonTitle = res.data.data.list[0].title
            // })
            this.$post('/system/getFreightPortJson1').then(res =>{
                    this.portJson = res.data.list
                    this.JsonTitle = res.data.list[0].title
                    // debugger
                    // console.log(res.data.list[0].title)
                    // debugger
            })
        },
        visibleChange(bool){
            // this.stylePort = bool
            if(bool){
                     this.stylePort = bool
            } 
            console.log(bool)
        },
        blurChange(){
            console.log(111)
             this.stylePort = false
        },
        selectSmall(name){
            // debugger
             this.params.targetSeaport = name
            this.stylePort = false
           
        },
        blurstartSeaport(){
            // params.startSeaport
        },
        paixu(){
            if(this.params.order == ''){
                // this.$set(this.params,'order','cabinet_amount' + ` desc` )
                // debugger
                this.params.order = 'cabinet_amount' + ` asc` 
            } else if(this.params.order == 'cabinet_amount asc'){
                this.params.order = 'cabinet_amount desc'
           } else if(this.params.order == 'cabinet_amount desc'){
               this.params.order = ''
           }
            // console.log(this.params.order)
        //    debugger
            this.getData()
        },
        jiangjiatixing(id){
            this.$confirm('是否确认开启降价提醒？', '确认信息', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消'
            })
          .then(() => {
            //   alert('111')
                console.log(this)
             this.addFreightNotify(id)
          })
          .catch(action => {
           
          });
        },
        addFreightNotify(id){
            // debugger
             this.$post('/freight/addFreightNotify?freightId=' + id,{freightId:id}).then(res =>{
                  
                    if(res.code == '200'){
                        this.$message.success('开启降价提醒成功')
                        this.getData()
                    }
              })
        },
        guanbi(id){
             this.$get('/freight/invalidUserFreightNotify?id='+id,{id:id}).then(res =>{
                if(res.code == '200'){
                    this.$message.success('关闭降价提醒成功')
                    this.getData()
                }
              })
        },
        addSearchFreightNotify(){
            if(this.value2 != null){
                this.jiangjia.sailTimeStart = this.value2[0]
                this.jiangjia.sailTimeEnd = this.value2[1]
            } else {
                this.jiangjia.sailTimeStart = ''
                this.jiangjia.sailTimeEnd = ''
            }
           if(this.jiangjia.startSeaport == ''){
               this.$message.error('请选择起运港')
               return false
           }
           if(this.jiangjia.startSeaport == ''){
               this.$message.error('请选择目的港')
               return false
           }
           if(this.jiangjia.cabinetShape == ''){
               this.$message.error('请选择柜型')
               return false
           }
            if(this.jiangjia.cabinetAmountStart == ''){
               this.$message.error('请输入最小预算价格')
               return false
           }
            if(this.jiangjia.cabinetAmountEnd == ''){
               this.$message.error('请输入最大预算价格')
               return false
           }
    //          private Date sailTimeStart;
    //    /**
    //     * 开船时间结束
    //     */
    //    private Date sailTimeEnd;

            this.$post('/freight/addSearchFreightNotify',this.jiangjia).then(res =>{
                if(res.code == '200'){
                    this.$message.success('设置航线提醒成功，我们将以短信形式通知给您')
                }
            })
        },
       
        handleClose(){
            this.dialogVisible = false
            this.jiangjia = {
                  startSeaport:'',
                changeSeaport:'',
                targetSeaport:'',
                shipCompanyName:'',
                cabinetShape:'',
                cabinetAmountStart:'',
                cabinetAmountEnd:''
            }
        },
        lianxikefu(){
            let token = localStorage.getItem('token')
             
             if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                this.token = ''
                this.$refs.headerTop.login()
            } else {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                console.log({
                     contact:'姓名：' + userInfo.user.userName + '公司名称：' +  userInfo.user.shopAddress,
                    email: userInfo.user.registerMail ?  userInfo.user.registerMail : '用户未注册邮箱',
                    tel: userInfo.user.phone,
                    weibo:'',
                    comment: '用户点击左侧固定栏进入'
                })
                _MEIQIA('metadata', {
                    contact:'姓名：' + userInfo.user.userName + '公司名称：' +  userInfo.user.shopAddress,
                    email: userInfo.user.registerMail ?  userInfo.user.registerMail : '用户未注册邮箱',
                    tel: userInfo.user.phone,
                    weibo:'',
                    comment: '用户点击左侧固定栏进入'
                })
                _MEIQIA('showPanel');
            } 
        },
        xiaoxi(){
            this.$toast.fail('敬请期待');
        },
        xunwen(rowData){
            // debugger
             let token = localStorage.getItem('token')
             
             if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                this.token = ''
                this.$router.push('/login')
            } else {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                _MEIQIA('metadata', {
                    contact:'姓名：' + userInfo.user.userName + '公司名称：' +  userInfo.user.shopAddress,
                    email: userInfo.user.registerMail ?  userInfo.user.registerMail : '用户未注册邮箱',
                    tel: userInfo.user.phone,
                    weibo:'',
                    comment: '航线id' + rowData.id
                })
                _MEIQIA('showPanel');
            }
        
        },
        fabucangwei(){
             let token = localStorage.getItem('token')
             if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                this.token = ''
                  this.$refs.headerTop.login()
            } else {
            //    this.zindeshow()
                this.$refs.sellingPort.errshowoLoad()
                //   this.$emit('updataSelect')
                // this.$router.push('/user?type=2')
            }
            //  _MEIQIA('metadata', {
            //     comment:  '客服点击发布舱位发起对话'
            // })
            //  _MEIQIA('showPanel');  
        },
         hangxiangShow(){
           
             let token = localStorage.getItem('token')
             if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                this.token = ''
                 this.$refs.headerTop.login()
                
            } else {
                // this.$router.push('/seek')
                // this.dialogVisible = true
                // this.zIndex = true
                 this.$refs.seekPort.showDialogVisible()
            }
          
        },
        getData(){
            this.loading = true
            
            this.$post('/freight/getPreferredFreight',this.params).then(res =>{
                this.total = res.totalCount
              
                res.data.list.forEach(ele => {
                    
                    let data1 = new Date(ele.sailTime).getDay()
                    let data2 = new Date(ele.closePassTime).getDay()
                    if(data1 == 0){
                        ele.weekSail = 7
                    } else {
                        ele.weekSail = data1
                    }
                    if(data2 == 0){
                        ele.weekcloseFile = 7
                    } else {
                        ele.weekcloseFile = data2
                    }
                    // console.log(ele.surchargeAmountDetail != "[]",typeof ele.surchargeAmountDetail)
                    if(ele.surchargeAmountDetail){
                     
                       if(ele.surchargeAmountDetail != "[]" && ele.surchargeAmountDetail != "[\u0000]"){
                            ele.surchargeAmountDetail = JSON.parse(ele.surchargeAmountDetail)
                        let usdTotal = 0
                        let cnyTotal = 0
                        let cnyList = []
                        let usdList = []
                        ele.surchargeAmountDetail.forEach(ele1=>{
                            // debugger
                            if(ele1.feeType == 'CNY'){
                                cnyTotal += ele1.fee
                                
                            }
                            if(ele1.feeType == 'USD'){
                              usdTotal += ele1.fee
                               
                            }
                            ele.usdTotal = usdTotal
                            ele.cnyTotal = cnyTotal
                        })
                       } else {
                           ele.surchargeAmount = 0
                           delete ele.surchargeAmountDetail
                       }
                    }
                    if(ele.supplyBizUserName) {
                        ele.mingzi =  ele.supplyBizUserName.substr(0,1) + "*"
                    } else {
                        // ele.mingzi = this.randomName()
                    }
                    if(ele.supplyBizUserPhone){
                         ele.tuomin = this.randomPhoneNumber()
                        //  ele.tuomin = ele.supplyBizUserPhone.substr(0, 3) + "****" + ele.supplyBizUserPhone.substr(7)
                    } else {
                        ele.tuomin = this.randomPhoneNumber()
                    }
                    if(ele.remark == '备注:'){
                        delete ele.remark
                    }
                    // <span>{{item.depositAmount}}</span>
                    if(ele.depositAmount == 0){
                        ele.depositAmount = '--'
                    } else if(!ele.depositAmount){
                         ele.depositAmount = '--'
                    }
                    // var reg = /[\u4e00-\u9fa5]/g;
                    // var names = ele.startPortName.match(reg);
                    // console.log(this.getParenthesesStr(ele.startPortName))
                    ele.startPortName1=  this.getParenthesesStr(ele.startPortName)
                    ele.compositeGrade = 5
                    ele.closeFileTime = ele.closeFileTime.substr(0, 11)
                    // debugger
                    ele.closePassTime = ele.closePassTime.substr(0, 11)
                    ele.sailTime = ele.sailTime.substr(0, 11)
                    // ele.
                    // val.substr(0, 3) + "****" + val.substr(7)
                    
                    //  var dateArray = element.sailTime.split("-");
                    // date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
                });
                
               
                this.tableData = res.data.list
                   console.log(1111,this.tableData)
                   this.loading = false
               
            }).catch(err =>{
                this.loading = false
            })
        },
        timeSub(time){
            var timearr = times.replace(" ", ":").replace(/\:/g, "-").split("-");
             var timestr = ""+timearr[0]+"/" + timearr[1] + "/" + timearr[2]
            return a
        },
        getData2(){
            this.loading = true
             if(this.value1 != null && this.value1.length != 0){
                this.params.sailTimeStart = this.value1[0] + ' 00:00:00'
                this.params.sailTimeEnd = this.value1[1] + ' 23:59:59'
            } else {
                this.params.sailTimeStart = ''
                this.params.sailTimeEnd = ''
            }
            this.$post('/freight/getPreferredFreight',this.params).then(res =>{
                this.total = res.totalCount
                this.loading = false
                res.data.list.forEach(ele => {
                    
                    let data1 = new Date(ele.sailTime).getDay()
                    let data2 = new Date(ele.closePassTime).getDay()
                    if(data1 == 0){
                        ele.weekSail = 7
                    } else {
                        ele.weekSail = data1
                    }
                    if(data2 == 0){
                        ele.weekcloseFile = 7
                    } else {
                        ele.weekcloseFile = data2
                    }
                    if(ele.surchargeAmountDetail){
                     
                       if(ele.surchargeAmountDetail != "[]" && ele.surchargeAmountDetail != "[\u0000]"){
                            ele.surchargeAmountDetail = JSON.parse(ele.surchargeAmountDetail)
                        let usdTotal = 0
                        let cnyTotal = 0
                        let cnyList = []
                        let usdList = []
                        ele.surchargeAmountDetail.forEach(ele1=>{
                            // debugger
                            if(ele1.feeType == 'CNY'){
                                cnyTotal += ele1.fee
                                
                            }
                            if(ele1.feeType == 'USD'){
                              usdTotal += ele1.fee
                               
                            }
                            ele.usdTotal = usdTotal
                            ele.cnyTotal = cnyTotal
                        })
                       } else {
                           ele.surchargeAmount = 0
                           delete ele.surchargeAmountDetail
                       }
                    }
                    if(ele.supplyBizUserName) {
                        ele.mingzi =  ele.supplyBizUserName.substr(0,1) + "*"
                    } else {
                    }
                    if(ele.supplyBizUserPhone){
                         ele.tuomin = this.randomPhoneNumber()
                    } else {
                        ele.tuomin = this.randomPhoneNumber()
                    }
                    if(ele.remark == '备注:'){
                        delete ele.remark
                    }
                    if(ele.depositAmount == 0){
                        ele.depositAmount = '--'
                    } else if(!ele.depositAmount){
                         ele.depositAmount = '--'
                    }
                   ele.startPortName1=  this.getParenthesesStr(ele.startPortName)
                    ele.compositeGrade = 5
                   ele.closeFileTime = ele.closeFileTime.substr(0, 10)
                   ele.closePassTime = ele.closePassTime.substr(0, 10)
                   ele.sailTime = ele.sailTime.substr(0, 10)
            });
                
               if(res.data.length != 0){
                      this.tableData = this.tableData.concat(res.data.list)
                   console.log(1111,this.tableData)
               }
             
               
            }).catch(err =>{
                this.loading = false
            })
        },
//         randomName(){
//             let firstThree = ["赵","钱","孙","李","周","吴","郑","王","冯","陈","褚","卫","蒋","沈","韩","杨","朱","秦","尤",
//   "许","何","吕","施","张","孔","曹","严","华","金","魏","陶","姜","戚","谢","邹","喻","柏","水",
//   "窦","章","云","苏","潘","葛","奚","范","彭","郎","鲁","韦","昌","马","苗","凤","花","方","俞",
//   "任","袁","柳","酆","鲍","史","唐","费","廉","岑","薛","雷","贺","倪","汤","滕","殷","罗","毕",
//   "郝","邬","安","常","乐","于","时","傅","皮","卞","齐","康","伍","余","元","卜","顾","孟","平",]
//             let flag = Math.floor(Math.random() * 20)
//             let number = `${firstThree[flag]}*`
//             let txt = ''
//             txt = number
//             return txt 
//         },
        getParenthesesStr(text) {
            let result = ''
            // debugger
            // if (isObjEmpty(text))
                // return result
            let regex = /\((.+?)\)/g;
            let options = text.match(regex)
            // if (!isObjEmpty(options)) {
                let option = options[0]
                // if (!isObjEmpty(option)) {
                    result = option.substring(1, option.length - 1)
                // }
            // }
            // console.log(result)
            return result
        },
          // 随机生成电话号码
        randomPhoneNumber () {
            let firstThree = [151, 152, 199, 138, 150, 182, 168, 135, 136, 158, 159, 177, 153, 154, 155, 157, 131, 132, 137, 188]
            let lastFour = []
            let flag = Math.floor(Math.random() * 20)
            for (let i = 0; i < 4; i++) {
                lastFour.push(Math.floor(Math.random() * 10))
            }
            let number = `${firstThree[flag]}****${lastFour.join('')}`
            let txt = ''
            txt = number
            return txt
        },
        getFreightPort(val){
            this.params.startSeaport = val
            this.$post('/system/getFreightPort',{name:val}).then(res =>{
                
                this.FreightPortList1 = res.data.list
                if(!val){
                    this.FreightPortList1.unshift({
                        cname:'深圳',
                        name:'SHENZHEN',
                        id:999999
                    })
                }
                // this.FreightPortList2 = res.data.list
                // this.FreightPortList3 = res.data.list
            })
        },
        getFreightPort1(val){
            this.stylePort = false
             this.params.targetSeaport = val
            this.$post('/system/getFreightPort',{type:'target',name:val}).then(res =>{
                this.FreightPortList3 = res.data.list
                // this.FreightPortList2 = res.data.list
                // this.FreightPortList3 = res.data.list
            })
        },
        getFreightPort2(val){
             this.params.changeSeaport = val
            this.$post('/system/getFreightPort',{type:'transfer',name:val}).then(res =>{
                this.FreightPortList2 = res.data.list
                // this.FreightPortList2 = res.data.list
                // this.FreightPortList3 = res.data.list
            })
        },
        getFreightPort3(val){
            this.$post('/system/getFreightPort',{name:val}).then(res =>{
                this.FreightPortList4 = res.data.list
                // this.FreightPortList2 = res.data.list
                // this.FreightPortList3 = res.data.list
            })
        },
        getFreightPort4(val){
            this.$post('/system/getFreightPort',{type:'target',name:val}).then(res =>{
                this.FreightPortList5 = res.data.list
                // this.FreightPortList2 = res.data.list
                // this.FreightPortList3 = res.data.list
            })
        },
        getFreightPort5(val){
            this.$post('/system/getFreightPort',{type:'transfer',name:val}).then(res =>{
                this.FreightPortList6 = res.data.list
                // let a = []
                // res.data.list.forEach(ele =>{
                  
                //     if(ele.firstCountryName){

                //     }
                // })
                // a.forEach(ele =>{
                    
                // })
                // console.log(a)
                // this.FreightPortList2 = res.data.list
                // this.FreightPortList3 = res.data.list
            })
        },
        getFreightCompany(val){
            this.$post('/system/getFreightCompany',{name:val}).then(res =>{
                this.companyList = res.data.list
            })
        },
         getFreightCompany1(val){
            this.$post('/system/getFreightCompany',{name:val}).then(res =>{
                this.companyList1= res.data.list
            })
        },
        sizeChange(val){
            this.params.pageIndex = val
            // debugger
            this.getData2()
           
        },
        yuding(val){
            // if()
            let token = localStorage.getItem('token')
            if(this.value1 != null && this.value1.length != 0){
                // this.params.sailTimeStart = this.value1[0] + ' 00:00:00'
                // this.params.sailTimeEnd = this.value1[1] + ' 23:59:59'
            } else {
                
            }
            if (this.params.startSeaport==undefined){
                   this.params.startSeaport=""
            }
         
            if(this.params.targetSeaport==undefined){
                 this.params.targetSeaport=""
            }
           
            let params={
              startSeaport:this.params.startSeaport,
              targetSeaport:this.params.targetSeaport,
              companyName:this.params.shipCompanyName,
              cabinetShape:this.params.cabinetShape,
              startDate:this.params.sailTimeStart,
              endDate:this.params.sailTimeEnd
            }
            this.$post("/index/click",params).then((res)=>{
              console.log(res);
            })

            if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                this.token = ''
                console.log(this.$refs.headerTop)
                this.$refs.headerTop.login()
                // this.$router.push('/login')
            } else {
                 this.$router.push("/subOrder?id=" + val.id + '&freightVoSkuList=' + JSON.stringify(val.freightVoSkuList))
            }
           
        },
        getFreightDefineEnums(){
                this.$get('/system/getFreightDefineEnums').then(res =>{
                    this.getFreightDefineEnumsList = res.data
                })
            },
       
    },
}
</script>
<style lang="scss" scoped>
#app{
    background: #fff;
}
.boxInfo{
    display: flex;
    justify-content: space-between;
}
.bigImage{
    height: 247px;
    position: relative;
    margin-bottom: 100px;
    background: #fff;
    //  z-index: -1;
}
.searchBox{
    //  position: absolute;
    //   align-items:center;
    position: absolute;
    left: 0;
    right:0;
    bottom: -80px;
    height: 126px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // margin-left: 0px;
    margin: 0 auto;
}
.searchimte{
    box-shadow: 0px 4px 40px 1px rgba(0, 186, 253, 0.2);
    border-radius: 8px;
    border: 4px solid #FFFFFF;
    width: 1188px;
    margin: 0 auto;
    border-right: none;
    background: #F9F9F9;
    position: relative;
}
.search{
    height: 106px;
    background: #fff;
    // line-height: 100px;
    // text-align: center;
  display: flex;

    align-items: center;
    justify-content: space-between;
}
.searchImage{
   width: 131px;
height: 48px;
background: rgba(0, 186, 253, 1);
border-radius: 8px;
    background: rgba(0, 186, 253, 1);
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    
    color: rgba(255, 255, 255, 1);
    margin-right: 16px;
    // border-radius: 8px 0 0 8px;
    // border-left: ;
}
.searchImage img{
    width: 19px;
    height: 19px;
    margin-right: 5px;
}
.searchInput{
    // margin-top: 8px;
    margin-left: 12px;
    font-weight: 500;
    display: flex;
    // height: 48px;
}
.el-select{
    width: 162px;
}
// ::v-deep  .el-input{
//     //   width: 162px !important;
//     //   background: #F5F5F5 !important;
// }
.kefu{
    width: 50px;
    height: 22px;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    border-radius: 4px;
border: 1px solid #1677FF;
    color: #1677FF;
}
.yuding{
    background: #1677FF;
    color: #fff;
}
.table{
    width: 1120px;
    margin: 0 auto;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    padding: 0 20px;

    background: #FFFFFF;
    border: 4px solid #FFFFFF;
    margin-bottom: 100px;
}
.tableData{
    box-shadow: 0px 0px 8px 0px rgba(51, 51, 51, 0.2);
    border-radius: 4px;
    width: 1480px;
    margin: 0 auto;
}
.tableData img{
    width: 252px;
    height: 184px;
}
.pagination{
    text-align: center;
     margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 60px;
   
}
.tabletitle{
    display: flex;
   justify-content: space-between;
    align-items: center;
    margin: 40px 40px 20px 40px
}
.shezhi{
//   width: 112px;
// height: 40px;
// background: #1677FF;
// box-shadow: 0px 2px 4px 0px rgba(26, 87, 171, 0.5);

    // background: #1677FF;
    line-height: 40px;
    border-radius: 7px;
    // color: #fff;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
   width: 104px;
    height: 40px;
    background: #00BAFD;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    color: #fff;
        // border: 2px solid #00BAFD;
}
.kuaisudingcang1:hover{
    // color: red;
    border-bottom: 2px solid #00BAFD;
}
.nodatabox{
    width: 1120px;
    height: 521px;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    margin: 0 auto;
    border: 4px solid #FFFFFF;
    display: flex;
    // align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}
.nodatabox img{
    // margin-top: 80px;
    width: 252px;
    height: 184px;
}
.dis{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 24px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #333333;
line-height: 33px;
}
.link{
    color:#1677FF;
    cursor: pointer;
}
.fujiafei{
    display: flex;
    justify-content: space-between;
}
.caozuoDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.van-rate{
    cursor: pointer !important;
}
::v-deep .el-table__row{
    font-size: 10px !important;
}
.caret{
    display: flex;
    flex-direction: column-reverse;
}
.paixu{
    display: flex;
    align-items: center;
}
.paixu i{
    margin-left: 5px;
    width: 10px;
    height: 10px;
}
.fabucangwei{
   
    width: 116px;
    height: 42px;
    margin-left: 10px;
}
.teableContent{
    display: flex;
    
}
  .image{
        // margin-top: 12px;
        // width: 200px;
        // height: 200px;
        // background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-bottom: 20px;
    }
.image img{
    // margin-top: 20px;
    width: 130px !important;
    height: 130px;
    border-top: 1px solid #333;
}
::v-deep .el-popover{
    min-width: 100px !important;
}
.popreptext{
    margin-top: 10px;
    font-size: 10px;
}
.activg{
    width: 76px;
    height: 36px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #D8D8D8;
    display: flex;
    align-items: center;
    justify-content: center;
}
.activg img {
    width: 32px;
    height: 30px;
    cursor: pointer;
}
.talbtitle1{
    width: 1120px;
    margin: 0 auto;
    height: 70px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: flex-end;
}
// .tableDataFor{
//     // display: flex;
//     // width: 1188px;
//     // justify-content: space-between;
// }
.tableDataFor >div{
    float: left;
    // display: inline-block;
    width: 385px;
    height: 130px;
    background: #fff;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 1px #DADADA;
    border-radius: 5px 5px 5px 5px;
    margin-right: 15px;
    margin-top: 15px;
    cursor: pointer;
}
.tableDataFor >div:hover{

box-shadow: 0px 4px 20px 1px #00BAFD, inset 0px 4px 4px 1px #00BAFD;
}
// .tableDataFor >div:nth-child(1){
//     margin-top: 0;
// }
.tableDataFor>div:nth-of-type(3n){
    margin-right:0
}
// .tableDataFor > div:nth-child(even){
//     margin-left: 15px !important;
//     margin-right: 15px !important;
// }

.tableHeader{
    height: 63px;
    background: #F5F5F5;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;

}
.tableText{
    font-size:12px
}
.tableContentLeft{
    margin-top: 10px;
    // height: 140px;
    margin-left: 16px;
    margin-bottom: 10px;
    width: 300px;
    border-right: 1px dashed #979797;;
}
.tabletextContert{
    font-size:14px;
    height: 20px;
    line-height: 20px ;
    text-align: left;
    font-weight: 500;
}
.clearfix:after {
  content:""; 
  display: block; 
     clear:both; 
}
.shuxian{
    margin: 0 12px;
    height: 20px;
    border-left:  0.5px solid #333;
}
.shuxian1{
      margin: 0 12px;
    border-left:  0.5px solid #333;
}
.smllImg{
    width: 16px;
    height: 16px;
    margin-right: 12px;
}
.smallgk{
    width: 16px;
    height: 16px;
    margin-left: 18px;
    margin-right: 40px;
    // position: relative;
}
.smalltitle1{
    width: 200px;
    display: flex;
    align-items: center;
    top: 0px;
    // left:8px;
    // left:50%;
    left: 20%;
    //  transform: translateX(-50%);
    font-size: 12px;
//    margin-left:-22px;
    position: absolute;
}
.smalltitle2{
    width: 400px;
    bottom: 0px;
    // left:16px;
    font-size: 12px;
    // left:50%;
//    margin-left:-22px;/
//  display: flex;
//     align-items: center;
    position: absolute;
    left: -33%;
    //  transform: translateX(-50%)
}
.smalltitle3{
    width: 200px;
    display: flex;
    align-items: center;
    // top: -15px;
    left:8px;
    // left:50%;
    font-size: 12px;
//    margin-left:-22px;
    position: absolute;
}
.smalltitle2{
    width: 400px;
    bottom: 0px;
    // left:16px;
    font-size: 12px;
    // left:50%;
//    margin-left:-22px;/
//  display: flex;
//     align-items: center;
    position: absolute;
    // left: -8px;
    //  transform: translateX(-50%)
}
.leftContent{
    display: flex;
    // align-content: center;
    align-items: center;
    // height: 14px;
    // height: 20px;
  justify-content: center;
  position: relative;
}
.smallgk{
    display: flex;
    align-items: center;
    justify-items: center;
}
.port{
    // width: 120px;
    max-width: 120px;
    font-size: 14px;
    cursor: pointer;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.port1{
    //  max-width: 120px;
    font-size: 12px;
    cursor: pointer;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;  
}
.time{
    font-size: 14px;
    color: #1677FF;
    cursor: pointer;
    display: flex;
}
.timeBorder{
    padding-right: 10px;
    border-right: 1px solid #1677FF;
}
.remark{
  font-weight: 400;
color: #464646;
text-decoration:underline;
margin-right: 8px;
}
.tableRight{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
}
.jiage{
    
color: #FE7617;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
}
.fujiafei{
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1677FF;
    line-height: 20px;
    margin-top: 4px;
}
.kuicangfei{
    margin-top: 6px;
}
.ydcg{
    font-size: 14px;
    color: #fff;
    margin-top: 13px;
    width: 139px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background: #69C8ED;
    border-radius: 2px 0px 0px 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.kefu1{
   width: 28px;
height: 28px;
cursor: pointer;
background: rgba(22, 119, 255, 0.14);
border-radius: 0px 2px 2px 0px;
// opacity: 0.14;
text-align: center;
line-height: 28px;
}
.tableShu >div{
    margin-top: 24px;
    height: 204px;  
    background: #FFFFFF;
box-shadow: 0px 0px 8px 0px rgba(51, 51, 51, 0.2);
border-radius: 4px;
}
.tableData >div:nth-child(1){
    margin-top: 20px;
}
.tableShu >div:nth-child(1){
    margin-top: 0 !important;
}
.tableShu{
    min-height: 500px;
}
.tableshuHeader{
  height: 44px;
   display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
background: #F1F7FF;
border-radius: 4px 4px 0px 0px;
}
.flexCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
.shubox{
    height: 44px;
    margin-top: 13px;
    margin-left: 24px;
    display: flex;
    align-items: center;
}
.shujieguan{
    display: flex;
    // margin-left: 200px;
}
.boxInfoLeft{
    color: rgba(172, 172, 172, 1);
    font-size: 12px;

}
.boxInfoLeft > div{
    line-height: 14px;
}
.searchPort{
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-bottom: 8px;
}
::v-deep  .searchSelect .el-input{
   width: 305px !important;
    height: 48px !important;
    // background: #F5F5F5 !important;
    border-radius: 5px !important;
}
::v-deep  .searchSelect1 .el-input__inner{
    height: 48px !important;
}
::v-deep .el-input__icon{
    font-weight:900 !important;
    color: #333 !important;
}
::v-deep .searchSelect .el-input__inner{
     height: 48px !important;
}
// .el-select{
//     //  width: 155px !important;
//     //  background: #F5F5F5 !important;
// }

.ml-20{
    margin-left: 9px;
    position: relative;
}
.el-date-editor{
    width: 250px;
}
.stylePort{
    position: absolute;
    z-index: 99999 !important;
    width: 540px;
    display: block;
    height: 360px;
     box-shadow: 0px 0px 40px 1px rgba(0, 186, 253, 0.2);
    // box-shadow:0 0 20px 0px #00bafd, inset 0 0 0 0px #00bafd;
    background: #fff;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.jsonTitle{
    display: inline-block;
//    height: 26px;
   text-align: center;
   line-height: 26px;
//    padding: 0 12px;
   margin-right: 15px;
    background: #FFFFFF;
    cursor: pointer;
    font-size: 12px;
}
.pordtTitle{
    margin-top: 8px;
    // width: 100%;
    padding:0 10px;
    // position: fixed;
    z-index: 999;
    background: #fff;
    font-size: 14px;
    //   border-bottom:1px solid rgba(22, 119, 255, 1);
}
.portJsonSmallbox{
    height: 300px;
    overflow-y: scroll;
}
// .titleborder{
//     // border-bottom: none !important;
//     // height: 48px;
// }
.titleborder1{
   background: #00BAFD;
   color:#fff;
   padding: 3px 8px;
   text-align: center;
   border-radius: 35%;
}
.portJsonSmall{
    // border-left: 3px solid rgba(22, 119, 255, 1);
    height: 36px;
    // background: #F9F9F9;
    line-height: 36px;
    text-align: left;
    padding-left: 11px;
    font-size: 14px;
    font-weight: 500;
    margin: 15px 15px 0 15px;
    color:rgba(196, 196, 196, 1)
    // padding: 0 8px;
}
.smallJson{
   margin: 10px;
}
.smallJson li{
    // display: block;
    // line-height: 24px;
    // width: 172px;
    // padding: 4px 6px;
    // text-align: left;
    // font-size: 12px;
    // color: #325d97;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    float: left;
    // cursor: pointer;
}
.smallJson li :hover{
    // background: rgba(22, 119, 255, 0.1);
    color:rgba(0, 186, 253, 1)
}
.smallJson li span{
 display: block;
    line-height: 24px;
    width: 172px;
    padding: 4px 6px;
    text-align: left;
    font-size: 12px;
    color: #325d97;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.selectSmall{
    background: #1677FF;
    color: #fff !important;
} 
.time{
    display: flex;
    align-items: center;
}
::v-deep .is-in-pagination{
    width: 50px !important;
}
.pagination{
    display: flex;
    align-items: center;
    justify-content: center;
}
.quedingdiv{
    width: 50px;
height: 32px;
background: #1677FF;
box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
border-radius: 2px;
line-height: 32px;
text-align: center;
margin-left: 8px;
color: #fff;
cursor: pointer;
}
.curor{
    cursor: pointer;
}
.flexcenter{
    display: flex;
    align-items: center;
}
.lunchuan{
    position: relative;
}
.reack{
    position: relative;
}
.righttFeid{
    position: fixed;
    right: 15px;
    z-index: 999999;
    // background: red;
}
.righttFeid > div{
    width: 44px;
    height: 44px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
   display: flex;
   align-items: center;
   justify-content: center;
    margin-top: 12px;
    cursor: pointer;
}
.righticon{
    width: 32px;
    height: 26px;
}
.leftContent span:not(:first-child){
    margin-left: 7px;
} 
.guixing{
    display: flex;
    align-items: center;
    // justify-content: center;
    min-width: 100px;
    width: 200px;
}
.guixing1 span:not(:first-child){
    margin-left: 7px;
} 
.disCenterbox{
    display: flex;
    align-items: center;
}
.youxuanBox{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    font-weight: 900;
    font-size: 20px;
}
.hengxian{
    width:200px;height:2px;margin:0px auto;padding:0px;background-color:#D5D5D5;overflow:hidden;
}
::v-deep .el-select-dropdown {
    z-index: 1 !important;
}

.zIndex{
    z-index: -1 !important;
}
.mainContent{
    width: 1188px;
    // background: red;
    // height: 500px;
    padding-bottom: 45px;
    margin: 0 auto;
}
.mainTitle{
   padding-top: 35px;
   padding-bottom: 12px ;
   font-size: 24px;
   font-weight: 400;
   border-bottom: 1px solid rgba(0, 186, 253, 1);;
   padding-left: 26px;
   width: 150px;
}
.mainBox{
    background: #fff;
}
.box{
    margin: 20px 13px 25px 13px;
    // background: red;
}
.boxPort{
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    justify-content: space-between;
}
.poetText{
    font-size: 18px;
    font-weight: 400;
    color: #464646;
    width: 115px;
    font-weight: 400;
    color: #464646;
    overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
    word-wrap:break-word
}
.portCenter{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.money{
    margin-top: 15px;

    font-size: 18px;

    color: #FF0000;
    text-align: right;
}
.borderTitle{
width: 100%;
// padding-bottom: 13px;
border-bottom: 1px solid rgba(218, 218, 218, 1);
}
.gengduo{
   display: flex;
    justify-content: space-between;
font-size: 14px;
    margin-top:17px ;

// color: #00BAFD;
// font-size: 14px;
// cursor: pointer;
}
.gengduoshangp{
    color: #00BAFD;
font-size: 14px;
}
.tuijian{
    padding: 4px;
color: rgba(0, 186, 253, 1);
background: rgba(0, 186, 253, 0.2);

font-size: 12px;
border-radius: 5px 5px 5px 5px;
cursor: pointer;
}
.kuaisudingcang{
    position: absolute;
    width: 130px;
    // background: red;
    background: #fff;
    left: -4px;
    right:0;
    top: -50px;
    height: 50px;
    // display: flex;
    // margin: 0 auto;
    // height: ;
     display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 0px;
    // margin: 0 auto;
    border-radius: 10px 10px 0 0;
}
.fabubox{
    position: absolute;
    width: 400px;
left: 130px;
    top: -50px;
    display: flex;
}
.boxInfoRight1{
    display: flex;
    justify-content: flex-end;
}
.itemBox{
    position: relative;
}
.remarkData{
    position: absolute;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(70, 70, 70, 0.8);
border-radius: 5px 5px 5px 5px;
opacity: 1;
color: #fff;
display: flex;
align-items: center;
font-size: 18px;
justify-content: center;
}
.swiper-container{
    height: 100%;
    z-index: 0;
}
.tuijian1{
   font-size: 24px;
    font-weight: 400;
}
.imageBox{
    width: 100%;
    // height: 727px;
    // background: url('./1.png') no-repeat 100% 100%;
    // background-size: cover;
}
.my-pagination-clickable{
    margin: 0 auto;
    text-align: center;
    width: 100%;
    // position: absolute;
//    background: red;
//    width: 200px;
//    height: 20px;
// //    z-index: 1000;
}
.swiper-pagination-bullet-active{
    color: rgba(196, 196, 196, 1) !important;
}
.bulletActiveClass{
     color: rgba(196, 196, 196, 1) !important;
}
.imgBox{
    position: relative;
}
.tiyanbutton{
    position: absolute;
    background: #00BAFD;
    color: #fff;
    font-size: 20px;
    width: 11%;
    height: 7.5%;
    left: 45%;
    bottom: 12%;
    // text-align: center;
    // line-height:  7%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.box2{
    width: 1188px;
    margin: 0 auto;
}
.jianjie{
    margin-top: 80px;
    margin-bottom: 80px;
    display: flex;
    
}
.jianjiecenter{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.jianjietitle{
    height: 50px;
font-size: 24px;
font-family: Roboto-Regular, Roboto;
font-weight: 400;
color: #464646;
}
.jianjietitle1{
    // height: 68px;
font-size: 12px;
font-family: Roboto-Regular, Roboto;
font-weight: 400;
color: #464646;
}
.imageBoxBack{
    background: linear-gradient(90deg, #73DAFF 0%, rgba(115, 218, 255, 0) 100%);
border-radius: 0px 0px 0px 0px;
height: 373px;
}
.zhuanshu{
    display: flex;
    margin-top: 54px;
    // width: 522px;
// height: 68px;
font-size: 36px;
// font-family: Roboto-Bold, Roboto;
font-weight: bold;
color: #464646;
line-height: 42px;
}
.box1{
    display: flex;
}
.boxLeftText{
//    height: 68px;
font-size: 18px;; 
text-align: left;
margin-top: 20px;
}
.boxRight{
    margin-left: 265px;
    margin-top: 68px;
   
font-size: 18px;
}
.historyBox{
    // padding-top: 20px;
    background: #fff;
    display: flex;
    padding-left: 24px;
     flex-flow: wrap;
    padding-bottom:26px
}
.historyTextBox{
    padding: 0 8px 0  8px ;
    color: #acacac;
    margin-right:11px;
    // min-width: 70px;
    height: 30px;
    line-height: 30px;
    // font-size: 18px;
    text-align: center;
    background: #F4F4F4;
    cursor: pointer;
}
.chuansiBox{
    background:red;width:270px;height:250px;display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
box-shadow: 0px 4px 20px 1px #DADADA;
border-radius: 5px 5px 5px 5px;
}
.chuansititle{
    text-align: right;
    font-size: 38px;
    font-weight: 900;
    margin-bottom: 50px;
}
.flex1{
    flex-flow: wrap;
}
.shezhi >img{
    background: #fff;
}
.flexcenterSz{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
</style>